import React from 'react';
import Image from '../../components/Image';
import PageInfo from '../../components/PageInfo';

function GuideHowToInstallGta5ScriptMods(props) {
  PageInfo(props);

  return (
    <div  className="text-align-center">
      <div className="title-section">
        How to Install GTA 5 Script Mods
      </div>

      <div className="guide">

        <div className="general guide-contents">
          <div className="subtitle-section">Contents</div>
          <ul>
            <li><a href="#scripthookv">Install ScriptHookV Mods (.asi)</a></li>
            <li><a href="#scripthookvdotnet">Install ScriptHookVDotNet Mods (.dll)</a></li>
            <li><a href="#notes">Notes</a></li>
          </ul>
        </div>

        <div className="general" id="scripthookv">
          <div className="subtitle-section">Install ScriptHookV Mods (.asi)</div>
          <ul>
            <li className="guide-step">Download and install <a href="http://www.dev-c.com/gtav/scripthookv" target="_blank" rel="noreferrer">ScriptHookV</a> to the <b>Grand Theft Auto V</b> folder:{Image("/images/guide-installgta5scriptmods-scripthookv.webp", "Screenshot of ScriptHookV.dll and dinput8.dll being added to the Grand Theft Auto V folder", "guide-img", null, "29.42386831%")}</li>
            <li className="guide-step">Copy the mod's .asi files to the <b>Grand Theft Auto V</b> folder:{Image("/images/guide-installgta5scriptmods-scripthookvmods.webp", "Screenshot of an example mod, NativeTrainer.asi, being added to the Grand Theft Auto V folder", "guide-img", null, "29.52674897%")}</li>
          </ul>
        </div>

        <div className="general" id="scripthookvdotnet">
          <div className="subtitle-section">Install ScriptHookVDotNet Mods (.dll)</div>
          <ul>
            <li className="guide-step">Download and install <a href="http://www.dev-c.com/gtav/scripthookv" target="_blank" rel="noreferrer">ScriptHookV</a> to the <b>Grand Theft Auto V</b> folder:{Image("/images/guide-installgta5scriptmods-scripthookv.webp", "Screenshot of ScriptHookV.dll and dinput8.dll being added to the Grand Theft Auto V folder", "guide-img", null, "29.42386831%")}</li>
            <li className="guide-step">Download and install <a href="https://github.com/scripthookvdotnet/scripthookvdotnet/releases" target="_blank" rel="noreferrer">ScriptHookVDotNet</a> to the <b>Grand Theft Auto V</b> folder:<div id="scripthookvdotnet-nightly" className="guide-additional"><div className="inline"><img src={`/icons/warning16.webp`} className="icon-16" alt="Chrome" /></div>Please take note of game version compatibility on the GitHub page, if you experience issues on newer versions of GTA 5 you may need to install a <a href="https://github.com/scripthookvdotnet/scripthookvdotnet-nightly/releases" target="_blank" rel="noreferrer">nightly build</a> of ScriptHookVDotNet instead.</div>{Image("/images/guide-installgta5scriptmods-scripthookvdotnet.webp", "Screenshot of ScriptHookVDotNet files being added to the Grand Theft Auto V folder", "guide-img", null, "29.42386831%")}</li>
            <li className="guide-step">If you don't have one, create a scripts folder within the <b>Grand Theft Auto V</b> folder:{Image("/images/guide-installgta5scriptmods-scripthookvdotnetfolder.webp", "Screenshot of a scripts folder being created in the Grand Theft Auto V folder", "guide-img", null, "29.83539094%")}</li>
            <li className="guide-step">Copy the mod's .dll and .ini files to the <b>scripts</b> folder:{Image("/images/guide-installgta5scriptmods-scripthookvdotnetmods.webp", "Screenshot of an example mod, Cougars.dll, being added to the scripts folder along with additional files", "guide-img", null, "28.71485943%")}</li>
            <li className="guide-step">Launch the game. SilverFinish mods display messages on the top right of the screen when initialized properly after a script reload or loading a save. Press the keys shown to interact with them:{Image("/images/guide-installgta5scriptmods-ingame.webp", "In-game screenshot showing Cougars and SFTools being initialized, with Right Arrow and NumPad 1 configured to launch the menu.", "guide-img", null, "31.11111111%")}</li>
          </ul>
        </div>

        <div className="general" id="notes">
          <div className="subtitle-section">Notes</div>
          <ul>
            <li className="guide-step">Refer to the mod's installation instructions for any additional files.</li>
            <li className="guide-step">If you're using the Rockstar Games Launcher, you may need to run the game as administrator for certain mods to work.</li>
            <li className="guide-step">When experiencing issues with SHVDN mods, locate ScriptHookVDotNet.log in the Grand Theft Auto V folder. If it is not there, check your installation of SHVDN. Otherwise, look inside to get more information on the issue. Errors relating to SHVDN.NativeMemory may require a nightly build as mentioned <a href="#scripthookvdotnet-nightly">above</a>.</li>
            <li className="guide-step">For further help or feedback, feel free to contact me on the <a href="https://discord.com/invite/QbhWpsX" target="_blank" rel="noreferrer">SilverFinish Discord Server</a>.</li>
          </ul>
        </div>

      </div>

    </div>
  );
}

export default GuideHowToInstallGta5ScriptMods;
