import React from "react";

function YTEmbed(props) {
  return (     
    <div className="video-cont-nobg">
      <div className="video-wrap" style={{backgroundImage: `url(https://i.ytimg.com/vi_webp/${props.ytUrl}/maxresdefault.webp)`}}>
        {(localStorage.getItem(`/consent/youtube`) === "true") ? 
          <iframe
          className="video-iframe"
          width="720"
          height="405"
          data-consent="analytics"
          src={`https://www.youtube.com/embed/${props.ytUrl}`}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen/> :
            <div className="video-consent">
              <div className="sf-text">Enable 'YouTube Embeds' to view this video</div>
              <div onClick={() => props.openConsent("youtube")} style={{cursor: "pointer", marginTop: "16px"}}>
                Cookie Settings
              </div>
              <div style={{marginTop: "16px"}}>
                <a href={`https://youtu.be/${props.ytUrl}`} target="_blank" rel="noreferrer">
                  Watch on YouTube
                </a>
              </div>
            </div>}
      </div>
    </div>
  )
}

export default YTEmbed;