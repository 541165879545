export function randInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export function createToken(length) {
  if (length == null) {
    length = 16;
  }
  var chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var list = chars.split('');
  var send = '';
  for (var step = 0; step < length; step++) {
    send = send + list[randInt(0, list.length - 1)];
  }
  return send; 
}

export function getTime() {
	return (new Date()).getTime();
}

export function timeAgo(time) {
	const times = { "y": 31536000, "w": 604800, "d": 86400, "h": 3600, "m": 60 };
	const keys = Object.keys(times);
	for (const item in keys) {
		const diff = (getTime() - Number(time)) / 1000;
		if (diff >= times[keys[item]]) {
			return Math.floor(diff / times[keys[item]]) + keys[item];
		}
	}
	return Math.floor((getTime() - Number(time)) / 1000) + "s";
}

export async function servicesPost(path, reqBody) {
  const requestOptions = {
    method: 'POST',
    headers: {
      "Content-Type": "application/json",
      "accepts":"application/json"
    },
    body: JSON.stringify(reqBody)
  };

  const response = await fetch(`/services${path}`, requestOptions);
  const body = await response.json();

  if (response.status !== 200) {
    throw Error(reqBody.message) 
  }

  return body;
}

export async function doFetch(method, path, reqBody) {
  try {
    const requestOptions = {
      method,
      headers: {
        "Content-Type": "application/json",
        "accepts": "application/json"
        //"x-user-name": currentUser(),
        //"x-user-token": currentToken(),
      },
      body: JSON.stringify(reqBody),
    };

    const response = await fetch(`${path}`, requestOptions);

    if ([401, 403].includes(response.status)) {
      window.dispatchEvent(new CustomEvent("logout", { detail: "/login" }));
      window.dispatchEvent(
        new CustomEvent("notify", { detail: "Please login to access this page" })
      );
    }

    if (response.status !== 200) {
      const text = await response.text();
      throw Error(text);
    }

    const body = await response.json();

    return body;
  } catch (err) {
    if (err.message.includes("Too many requests")) {
      window.dispatchEvent(
        new CustomEvent("notify", { detail: "Too many requests - please try again later" })
      );
    }
  }
}