import React, { useEffect, useState } from 'react';
import { doFetch } from '../common/utils';
import Image from '../components/Image';
import PageInfo from '../components/PageInfo';

function FeaturedNews(news) {
  if (news.length <= 0) {
    return null;
  }

  let featured = news[0];

  return (
    <div className="featured-news-item">
      <div className="featured-news-left">
        <div className="featured-news-title">{featured.title}</div>
        <div className="featured-news-content">{featured.content}</div>
      </div>
      <div className="featured-news-right">
        {featured.ytId ? <div className="featured-news-img">{Image(featured.imgUrl, "YouTube Thumbnail", null, featured.url, "37.5%")}</div> : null}
        {featured.link ? <div className="news-button"><a href={featured.link} target="_blank" rel="noreferrer">{featured.linkText}</a></div> : null}
      </div>
    </div>
  );
}

function Home(props) {
  PageInfo(props);

  const [news, setNews] = useState([]);

  async function fetchNews() {
    const news = await doFetch("GET", "/services/news");
    news.reverse();
    return news;
  }

  useEffect(() => {
    fetchNews()
      .then((res) => {
        setNews(res);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <div className="page-banner">
        {Image(`/images/banner-home.webp`, "Software Engineer and Content Creator", null, null, "24.73958333%")}
      </div>

      <div className="stats-bar">
        <div></div>
        { (props.stats?.youtube) ? <div className="stats-item"><img src={`/icons/yt16.webp`} alt="YouTube" />YouTube: {props.stats?.youtube?.subs} subs | {props.stats?.youtube?.views} views</div> : null } 
        { (props.stats?.discord) ? <div className="stats-item"><img src={`/icons/discord16.webp`} alt="Discord" />Discord: {props.stats?.discord?.members} members</div> : null}
        <div></div>
      </div>

      <div className="news-section">
        <div className="title-section">
          Latest News
        </div>
        <div className="featured-news">
          {FeaturedNews(news, props)}
        </div>
      </div>
    </div>
  );
}

export default Home;