import React from 'react';
import PageInfo from '../../components/PageInfo';
import { Link } from "react-router-dom";

const guides = [
  { name: "How to Install GTA 5 Script Mods", src: "install-gta-5-script-mods" }
];

function Guides(props) {
  PageInfo(props);

  return (
    <div className="text-align-center">

      <div className="title-section">
        Guides
      </div>

      <div className="subtitle-section">
        Read guides written by SilverFinish!
      </div>

      <div className="button-list">
        {guides.map((t) => {
          return (
            <Link className="button-list-item" to={`/guides/${t.src}`} key={t.src}>
              {t.name}
            </Link>
          )
        })}
      </div>
    </div>
  )
}

export default Guides;