import React, { useEffect }  from 'react';
import { LoadingText } from '../../components/Loading';
import Image from '../../components/Image';
import PageInfo from '../../components/PageInfo';
import YTEmbed from '../../components/YTEmbed';
import {
  Link
} from "react-router-dom";

export function RenderMod(props) {
  PageInfo(props);
  const mod = props.mod;

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="text-align-center">

      <div className="title-section">
        {mod.name}
      </div>
      <div className="subtitle-section">
        {"Grand Theft Auto V Mod"}
      </div>

      <div className="mod-details">
        
        <div className="mod-details-left">
          <div className="width-1024">
            <div className="text-section">
              { mod.desc }
            </div>
            <div className="subtitle-section">Requirements</div>
            <ul>
              <li><a href="http://www.dev-c.com/gtav/scripthookv" target="_blank" rel="noreferrer">ScriptHookV</a></li>
              <li><a href="https://github.com/scripthookvdotnet/scripthookvdotnet/releases" target="_blank" rel="noreferrer">ScriptHookVDotNet</a></li>
              { mod.requiresSftools ? <li>SFTools (Included)</li> : null }
            </ul>
            { mod.ytUrl ?
              <div className="width-1024">
                <div className="subtitle-section">Video</div>
                <div>{mod.ytUrl ? YTEmbed({ytUrl: mod.ytUrl, openConsent: props.openConsent}) : null}</div>
              </div>
            : null }
          </div>
        </div>

        <div className="mod-details-right">
          
          <div className="subtitle-section">Downloads</div>

          <div>

            {mod.files ? Object.keys(mod.files).map((f) => {
              return (
                <div key={f}>
                  <div className="mod-button text-align-center"><a href={`/download?id=${f}`} download>Download {mod.version}</a></div>

                  <div className="downloads-bar">
                    <div className="stats-item"><img src={`/icons/download16.webp`} alt="Download Icon" />{LoadingText(props.stats?.mods?.downloads[f])} downloads</div>
                  </div>

                  <div className="mod-checksum">MD5: {mod.files[f].md5}</div>
                </div>
              )
            }) : null}

            {mod.url ? 
              <div>
                <div className="mod-button text-align-center">
                  <a href={mod.url} target="_blank" rel="noreferrer">5Mods Page</a>
                </div>
                <div className="mod-version">Latest Version: {mod.version}</div>
              </div>
            : null}

            { !(mod.url || mod.download) ? <div className="mod-button-disabled text-align-center">Coming Soon</div> : null }

            <a href="/guides/install-gta-5-script-mods#scripthookvdotnet" target="_blank" rel="noreferrer">Installation Guide</a>

          </div>
        </div>

      </div>

      { mod.download && !mod.url ?
        <div className="margin-top padding-16">
          SilverFinish.net is the only official source for this mod - redistribution is not permitted
        </div>
      : null }
    </div>
  );
}

function Mods(props) {
  PageInfo(props);

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div className="text-align-center">

      <div className="page-banner">
        {Image(`/images/banner-mods.webp`, "Mods", null, null, "24.73958333%")}
      </div>

      <div className="stats-bar">
        {(props.stats?.mods) ? <div className="stats-item" style={{display: "flex", alignItems: "center"}}><div style={{width: "12px", height: "12px", marginRight: "4px"}}>{Image(`/icons/download16.webp`, "Download Icon")}</div>Total Downloads: {props.stats?.mods?.totalDownloads}</div> : null}
      </div>

      <div className="title-section">
        Grand Theft Auto V Mods
      </div>

      <div className="page-content">
        <div className="cards">
          {props.mods.map((m) => {
            return (
              <div className="cards-item" key={`${m.name}`} style={{width: "100%", height: "200px", maxWidth: "356px"}}>
                <Link to={`/mods/${m.src}`}>
                  {Image(`/mods/images/${m.src}.webp`, m.name)}
                  <div className="card-overlay">{m.name}</div>
                </Link>
              </div>
            )})}
        </div>
      </div>

      {props.mods.length > 0 ? <div className="margin-top padding-16">
        Any mods not shown here are unreleased or in development.
      </div> : null}

    </div>
  );
}

export default Mods;