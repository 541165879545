import React, { useState, useEffect } from "react";
import Home from "./pages/Home";
import Games, { RenderGame } from "./pages/games/Games";
import TransferSaves from "./pages/games/TransferSaves";
import VidSim from "./vidsim/VidSim";
import Mods, { RenderMod } from "./pages/mods/Mods";
import Tools from "./pages/tools/Tools";
import About from "./pages/about/About";
import ToolNumberVisualizer from "./pages/tools/NumberVisualizer";
import NotFound from "./pages/NotFound";
import { clearUserInfo, currentUser } from './common/user';
import { doFetch } from './common/utils';
import { Routes, Route, Link, useNavigate } from "react-router-dom";
import GuideHowToInstallGta5ScriptMods from './pages/guides/HowToInstallGta5ScriptMods';
import Guides from './pages/guides/Guides';

// ref={el => (this.div = el)}

function App() {

  const [user, setUser] = useState("");
  const [navOpen, setNavOpen] = useState(false);
  const [consentOpen, setConsentOpen] = useState(false);
  const [visit, setVisit] = useState(false);
  const [notification, setNotification] = useState("");
  const [windowSize, setWindowSize] = useState(getWindowSize());
  const navigate = useNavigate();

  const [games, setGames] = useState([]);
  const [mods, setMods] = useState([]);
  const [stats, setStats] = useState({});
  const [fetch, setFetch] = useState(false);

  const [consent, setConsent] = useState({
    necessary: { enabled: false, necessary: true, title: "Necessary", desc: "Allow the storage of data necessary for this website to function properly on your device, such as your responses to this cookie preferences form" },
    games: { enabled: false, title: "Game Data", desc: "Allow game data to be saved on your device, required to play HTML5 SilverFinish games" },
    youtube: { enabled: false, title: "YouTube Embeds", desc: "Allow YouTube embeds and their use of cookies on this website. By enabling this you accept the YouTube ", link: "https://www.youtube.com/t/terms", linkName: "Terms of Service", link2: "https://policies.google.com/privacy", linkName2: "Privacy Policy" },
  });

  async function fetchGames() {
    const news = await doFetch("GET", "/services/games");
    return news;
  }

  async function fetchMods() {
    const news = await doFetch("GET", "/services/mods");
    return news;
  }

  async function fetchStats() {
    const stats = await doFetch("GET", "/services/stats");
    return stats;
  }

  async function postNavigation(action, path) {
    const result = await doFetch("POST", "/services/analytics", { action, path });
    return result;
  }

  function getWindowSize() {
    const { innerWidth: width, innerHeight: height } = window;
    return { width, height };
  }

  function handleLogin() {
    setUser(currentUser());
  }

  function closeNotification(event) {
    setNotification("");
  }

  function handleLogout() {
    window.dispatchEvent(new CustomEvent("logout", { detail: "/" }));
  }

  function toggleNav() {
    setNavOpen(!navOpen);
  }

  function isMobileMode() {
    return windowSize.width <= 1000;
  }

  const url = window.location.pathname;
  useEffect(() => {
    postNavigation(visit ? "navigation" : "visit", url);
    if (!visit) setVisit(true);
  }, [url]);

  useEffect(() => {

    const size = getWindowSize();
    setupNav(size.width);

    // User
    const curUser = currentUser();
    if (curUser !== user) {
      setUser(curUser);
    }

    function doLogout(target = "/") {
      setUser("");
      clearUserInfo();
      navigate(target);
    }

    function setupNav() {
      setNavOpen(windowSize.width > 1000);
    }
  
    function handleResize() {
      const size = getWindowSize();
      setupNav(size.width);
      setWindowSize(getWindowSize());
    }

    const logoutEvent = (e) => doLogout(e.detail);
    window.addEventListener("logout", logoutEvent);
    const notifyEvent = (e) => setNotification(e.detail);
    window.addEventListener("notify", notifyEvent);

    // Content

    if (!fetch) {
      setFetch(true);

      fetchMods()
      .then((res) => {
        setMods(res);
      })
      .catch((err) => {
        console.log(err);
      });

      fetchGames()
      .then((res) => {
        setGames(res);
      })
      .catch((err) => {
        console.log(err);
      });

      fetchStats()
      .then((res) => {
        setStats(res);
      })
      .catch((err) => {
        console.log(err);
      });
    }

    // Resize

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("logout", logoutEvent);
      window.removeEventListener("notify", notifyEvent);
      window.removeEventListener("resize", handleResize);
    };
    
  }, [user, navigate, windowSize.width, mods, games, stats, consentOpen, fetch]);

  function openConsent(highlight) {
    const newConsent = {...consent};
    for (let i in newConsent) {
      newConsent[i].enabled = localStorage.getItem(`/consent/${i}`) === "true";
      newConsent[i].highlight = highlight === i;
    }
    //window.document.getElementsByTagName("html")[0].style.overflowY = "hidden";
    setConsent(newConsent);
    setConsentOpen(true);
  }

  function acceptAllConsent() {
    const newConsent = {...consent};
    for (let i in newConsent) {
      newConsent[i].enabled = true;
    }
    setConsent(newConsent);
    saveConsent();
  }

  function saveConsent() {
    for (let i in consent) {
      localStorage.setItem(`/consent/${i}`, i === "necessary" ? true : consent[i].enabled);
    }
    closeConsent();
  }

  function closeConsent() {
    //window.document.getElementsByTagName("html")[0].style.overflowY = "unset";
    setConsentOpen(false);
  }

  function consentChange(event) {
    setConsent({...consent, [event.target.name]: {
      ...consent[event.target.name], enabled: event.target.checked
    }});
  }

  return (
    <div>
      <div className="top-container">
        <div className="navbar-container">
          <div className="navbar-items">

            <div className="navbar-item navbar-logo-bright">
              <Link className="navbar-link" to="/">
                <img className="navbar-logo" src={`/logos/silverfinish.webp`} alt="SilverFinish" />
              </Link>
              {isMobileMode() ?
                <div className="navbar-expand" onClick={() => toggleNav()}>&#9776;</div>
              : null}
            </div>

            {navOpen ? (
              <div className="navbar-links" onClick={() => {setNavOpen(!isMobileMode())}}>
                <div className="navbar-item">
                  <Link className="navbar-link" to="/">Home</Link>
                </div>

                <div className="navbar-item">
                  <Link className="navbar-link" to="/games">Games</Link>
                  <div className="navbar-drop">
                    <Link className="navbar-drop-link" to="/games/bit-warfare">Bit Warfare</Link>
                    <Link className="navbar-drop-link" to="/games/outhack">OutHack</Link>
                    <Link className="navbar-drop-link" to="/games/idle-hacking">Idle Hacking</Link>
                  </div>
                </div>

                <div className="navbar-item">
                  <Link className="navbar-link" to="/mods">Mods</Link>
                  <div className="navbar-drop">
                    <Link className="navbar-drop-link" to="/mods/cougars">Cougars</Link>
                    <Link className="navbar-drop-link" to="/mods/kill-cam">Kill Cam</Link>
                    <Link className="navbar-drop-link" to="/mods/finite-cops">Finite Cops</Link>
                  </div>
                </div>

                <div className="navbar-item">
                  <Link className="navbar-link" to="/tools">Tools</Link>
                  <div className="navbar-drop">
                    <Link className="navbar-drop-link" to="/tools/number-visualizer">Number Visualizer</Link>
                  </div>
                </div>

                <div className="navbar-item">
                  <Link className="navbar-link" to="/about">About</Link>
                </div>

                {/*
                <div className="navbar-item navbar-item-login">
                  <Link className="navbar-link-login" to="/login"><div className="navbar-item-login-text">{user ? user : "Login"}</div></Link>
                </div>
                */}
              </div>
            ):
              null
            }
          </div>
        </div>
          <Routes>
            <Route exact path="/" element={<Home title="Home - SilverFinish" desc="Official home of SilverFinish - Software Engineer and Content Creator" stats={stats} openConsent={openConsent} />} />
            <Route exact path="/games" element={<Games title="Games - SilverFinish" desc="Browse and play free online games by SilverFinish, such as OutHack and Bit Warfare." games={games} />} />
            <Route exact path="/games/vidsim" element={<VidSim title="Vid Sim - SilverFinish" desc="Play Vid Sim." />} />
            <Route exact path="/games/transfer-saves" element={<TransferSaves title="Transfer Saves - SilverFinish" desc="Transfer game saves from the previous SilverFinish website." openConsent={openConsent} /> } />
            {games.map((g) => {
              return (
                <Route
                  exact
                  path={`/games/${g.src}`}
                  element={<RenderGame game={g} title={`${g.name} - SilverFinish`} desc={g.desc} openConsent={openConsent} />}
                  key={`${g.name}`}
                />
              );
            })}
            {games.map((g) => {
              if (g.oldSrc) {
                return (
                  <Route
                    exact
                    path={`/games/${g.oldSrc}`}
                    element={<RenderGame game={g} title={`${g.name} - SilverFinish`} desc={g.desc} openConsent={openConsent} />}
                    key={`${g.name}`}
                  />
                );
              }
            })}
            {mods.map((m) => {
              return (
                <Route
                  exact
                  path={`/mods/${m.src}`}
                  element={<RenderMod mod={m} title={`${m.name} ${m.shortGame} Mod - SilverFinish`} desc={m.desc} stats={stats} openConsent={openConsent} />}
                  key={`${m.name}`}
                />
              );
            })}
            <Route exact path="/mods" element={<Mods title="Mods - SilverFinish" desc="Browse and download GTA 5 mods by SilverFinish." mods={mods} stats={stats} />} />

            <Route exact path="/tools" element={<Tools title="Tools - SilverFinish" desc="Access browser-based tools from SilverFinish." />} />
            <Route
              exact
              path="/tools/number-visualizer"
              element={<ToolNumberVisualizer title="Number Visualizer - SilverFinish" desc="Turn any number into a visual representation on screen!" />}
            />

            <Route exact path="/guides" element={<Guides title="Guides - SilverFinish" desc="Read guides written by SilverFinish." />} />
            <Route
              exact
              path="/guides/install-gta-5-script-mods"
              element={<GuideHowToInstallGta5ScriptMods title="How to Install GTA 5 Script Mods - SilverFinish" desc="A guide on installing GTA 5 script mods using ScriptHookV and ScriptHookVDotNet." />}
            />

            <Route exact path="/about" element={<About title="About - SilverFinish" desc="Information and timeline about SilverFinish." />} />
            {/* <Route exact path="/login" element={<Login title="Login - SilverFinish" />} /> */}
            {(games.length > 0 && mods.length > 0) ? <Route path="*" element={<NotFound title="Not Found - SilverFinish" desc="Page not found." />} /> : null}
          </Routes>
        </div>

      <div className="footer-container">
        <div className="sf-text sf-version footer-left"></div>
        <div className="sf-text">© SilverFinish</div>
        <div className="vl"></div>
        <div className="footer-img"><a href="https://www.youtube.com/c/SilverFinish" target="_blank" rel="noreferrer"><img src={`/icons/yt64.webp`} alt="YouTube" /></a></div>
        <div className="footer-img"><a href="https://discord.com/invite/QbhWpsX" target="_blank" rel="noreferrer"><img src={`/icons/discord64.webp`} alt="Discord" /></a></div>
        <div className="vl"></div>
        <div onClick={openConsent} style={{cursor: "pointer"}}>Cookies</div>
        <div className="sf-text sf-version footer-right">v3.0.4</div>
      </div>
    
      {!localStorage.getItem(`/consent/necessary`) && !consentOpen ? (
        <div className="consent-notify">
          <div className="cont">
            <div className="col-div">
              By pressing "Accept All", you agree to the use of cookies on your device whilst browsing this website.
            </div>
            <div className="col-div">
              <div className="col-buttons">
                <input className="form-button" type="submit" value="Manage" onClick={openConsent}></input>
                <input className="form-button float-right" type="submit" value="Accept All" onClick={acceptAllConsent}></input>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div></div>
      )}

      {notification ? (
        <div className="notify">
          <div className="notify-cont">
            <div className="notify-data">

              <div className="cont">

                <div className="col-title">
                  <div className="notify-top">
                    {notification}
                  </div>
                </div>

                <div className="col-div">
                  <div className="col-buttons">
                    <input className="form-button" type="submit" value="Ok" onClick={closeNotification}></input>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : (
        null
      )}

      {consentOpen ? (
        <div className="notify">
          <div className="notify-cont">
            <div className="notify-data">

              <div className="cont">
                <div className="col-title">
                  <div className="notify-top">
                    Cookies
                  </div>
                </div>

                {Object.keys(consent).map(name => {
                  return (
                    <div key={name} className={consent[name].highlight ? "col-div col-div-highlight" : "col-div"}>
                      <label>
                        <div className="consent-title">
                          <input name={name} className="consent-checkbox" type="checkbox" defaultChecked={consent[name].necessary || consent[name].enabled} disabled={consent[name].necessary} onChange={consentChange}/>
                          {consent[name].title}
                        </div>
                      </label>
                      <div className="padding-6 color-ddd">
                        {consent[name].desc}
                        {consent[name].link ? (
                          <a href={consent[name].link} target="_blank" rel="noreferrer">
                            {consent[name].linkName}
                          </a>
                        ):null}
                        {consent[name].link2 ? (
                          <span>
                            <span> and </span>
                            <a href={consent[name].link2} target="_blank" rel="noreferrer">
                              {consent[name].linkName2}
                            </a>
                          </span>
                        ):null}
                        <span>.</span>
                      </div>
                    </div>
                  );
                })}

                <div className="col-div">
                  <div className="col-buttons">
                    <input className="form-button" type="submit" value="Save" onClick={saveConsent}></input>
                    <input className="form-button float-right" type="submit" value="Accept All" onClick={acceptAllConsent}></input>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
      ) : (
        null
      )}

    </div>
  );
}

export default App;
