import React from 'react';
import Image from '../../components/Image';
import PageInfo from '../../components/PageInfo';
import {
  Link
} from "react-router-dom";

export function RenderGame(props) {
  PageInfo(props);
  const game = props.game;

  return (
    <div className="game-content">

      <div className="title-section">
        {game.name}
      </div>

      {(localStorage.getItem(`/consent/games`) === "true") ? 
        <iframe
        title={game.name}
        className="game"
        src={`https://games.silverfinish.net/${game.src}`}
        frameBorder="0"/> : <div className="game-wrap"><div className="video-consent"><div className="sf-text">Enable 'Game Data' to play this game</div><div className="margin-top" onClick={() => props.openConsent("games")} style={{cursor: "pointer"}}>Cookie Settings</div></div></div>
      }

      <div className="margin-top general text-align-center">
        { game.desc }
        <div className="margin-top">
          <div className="inline"><img src={`/icons/warning16.webp`} className="icon-16" alt="Chrome" /></div>
          <div className="inline">This is an experimental port to HTML5 - </div>
          <div className="inline"><img src={`/icons/chrome16.webp`} className="icon-16" alt="Chrome" /></div>
          <div className="inline">Chrome is recommended for the most stable experience.</div>
        </div>
        <div className="margin-top">
        <div className="inline">Missing saves from the older website? Transfer them <Link to={`/games/transfer-saves`}>here!</Link></div>
        </div>
      </div>

    </div>
  );
}

function Games(props) {
  PageInfo(props);

  return (
    <div className="text-align-center">

      <div className="page-banner">
        {Image(`/images/banner-games.webp`, "Games", null, null, "24.73958333%")}
      </div>

      <div className="title-section">
        Browser Games
      </div>

      <div className="page-content">
        <div className="cards">
          {props.games.map((g) => {
            return (
              <div className="cards-item" key={`${g.name}`} style={{width: "250px", height: "200px"}}>
                <Link to={`/games/${g.src}`}>
                  {Image(`/games/images/${g.src}.webp`, g.name)}
                  <div className="card-overlay">{g.name}</div>
                </Link>
              </div>
            )})}
        </div>
      </div>
    </div>
  );
}

export default Games;