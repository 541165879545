import React, { useEffect, useState } from 'react';
import PageInfo from '../../components/PageInfo';

const games = {"idlehacking": "idle-hacking", "outhack": "outhack", "bitwarfare": "bit-warfare"};

function RenderGamesFrame(props) {

  function sendMessage() {
    document.getElementById('transfer-frame').contentWindow.postMessage(props.saves, "https://games.silverfinish.net");
  }

  return (
    <div className="margin-top">
      <iframe id="transfer-frame" title="Transfer Saves" className="game-transfer" src={`https://games.silverfinish.net/transfer-saves`} frameBorder="0" onLoad={sendMessage}/>
    </div>
  );
}

function TransferSaves(props) {
  PageInfo(props);

  const [transfer, setTransfer] = useState(false);
  const [saveData, setSaveData] = useState({});
  const [saveCount, setSaveCount] = useState(0);

  useEffect(() => {
    let saves = {};
    for (const game in games) {
      let stored = localStorage.getItem(`/games/${game}/:mySave`);
      if (!stored) continue;
      saves[games[game]] = stored;
    }

    setSaveCount(Object.keys(saves).length);

    setSaveData(saves);
  }, [props.title]);

  function handleTransfer() {
    setTransfer(true);
  }

  return (
    <div className="text-align-center">

      <div className="title-section">
        Transfer Saves
      </div>

      {(localStorage.getItem(`/consent/games`) === "true") ? 
      <div>
        <div className="margin-top">
          {(saveCount > 0) ? `${saveCount} save(s) from the previous website version have been found, click the button below to transfer them.` : "No saves from the previous website version have been found." }
          {(saveCount > 0) ?
            <div>
              <input className="form-button margin-top" type="submit" value="Transfer" onClick={handleTransfer}></input>
            </div>
          : null}
        </div>

        {(transfer) ? RenderGamesFrame({ saves: saveData }) : null}
      </div> :
      <div className="margin-top"><div className="sf-text">'Game Data' must be enabled to transfer saves</div><br/><div onClick={() => props.openConsent("games")} style={{cursor: "pointer"}}>Cookie Settings</div></div>}
      
    </div>
  );
}

export default TransferSaves;