import React, { useState } from 'react';
import PageInfo from '../../components/PageInfo';

function Dots(props) {
  return (
    <div className="dots">{props.dots}</div>
  );
}

function ToolNumberVisualizer(props) {
  PageInfo(props);

  const [dots, setDots] = useState("");
  const [num, setNum] = useState("");

  function validateNum(num) {
    if (Number(num) >= 1000000) {
      return "1000000"
    }
    return num
  }

  function handleChange(event) {
    setNum(validateNum(event.target.value));
    setDots(`🙂`.repeat(validateNum(event.target.value)));
  }

  return (
    <div className="text-align-center">

      <div className="title-section">
        Number Visualizer
      </div>

      <div className="subtitle-section">
        Turn any number into a visual representation on screen!
      </div>

      <div>
        <input
          className="form-input"
          type="number"
          placeholder="Enter a number"
          value={num}
          min="0"
          onInput={handleChange}
          style={{marginTop: "12px"}}
        />
      </div>
      <Dots
        dots={dots}
      />
    </div>
  );
}

export default ToolNumberVisualizer;

//ToolNumberVisualizer

//<input id="dotNum" type="number" onChange={this.dotsUpdate(this.value)} onInput={this.dotsUpdate(this.value)}></input>
//<div id="dots" className="dots">{this.renderDots()}</div>