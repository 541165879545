export function storeUserInfo(path, userInfo) {
  window.localStorage.setItem(`${path}/user`, userInfo.user);
  window.localStorage.setItem(`${path}/token`, userInfo.token);

  updateListeners();
}

export function clearUserInfo(path) {
  window.localStorage.clear(`${path}/user`);
  window.localStorage.clear(`${path}/token`);

  updateListeners();
}

export function currentUser(path) {
  return window.localStorage.getItem(`${path}/user`);
}

export function isLoggedIn(path) {
  return !!window.localStorage.getItem(`${path}/token`);
}

export function currentToken(path) {
  return window.localStorage.getItem(`${path}/token`);
}

function updateListeners() {
  window.dispatchEvent(new Event('Token'));
}
