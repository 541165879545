function Image(src, alt, className, link, ratio) {

  if (link) {
    return (
      <div className="img-container" style={{paddingTop: ratio}}>
        <a href={link} target="_blank" rel="noreferrer">
          <img src={src} className={className} alt={alt} style={{position: "absolute", top: 0, left: 0, width: "100%"}}></img>
        </a>
      </div>
    );
  }

  return (
    <div className="img-container" style={{paddingTop: ratio}}>
      <img src={src} className={className} alt={alt} style={{position: "absolute", top: 0, left: 0, width: "100%"}}></img>
    </div>
  );
  
}

export default Image;