import React from 'react';
import PageInfo from '../../components/PageInfo';
import { Link } from "react-router-dom";

const tools = [
  { name: "Number Visualizer", src: "number-visualizer" }
];

function Tools(props) {
  PageInfo(props);

  return (
    <div className="text-align-center">

      <div className="title-section">
        Tools
      </div>

      <div className="subtitle-section">
        Access browser-based tools from SilverFinish!
      </div>

      <div className="button-list">
        {tools.map((t) => {
          return (
            <Link className="button-list-item" to={`/tools/${t.src}`} key={t.src}>
              {t.name}
            </Link>
          )
        })}
      </div>

    </div>
  )
}

export default Tools;