import React from 'react';
import PageInfo from '../../components/PageInfo';

function FadeInSection(props) {
  const [isVisible, setVisible] = React.useState(false);
  const domRef: any = React.useRef();
  React.useEffect(() => {
    const ref = domRef.current;
    if (ref === undefined) return;
    const observer = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        setVisible(true);
        observer.unobserve(ref);
      }
    });
    
    observer.observe(ref);
    
    return () => observer.unobserve(ref);
  }, []);
  return (
    <div
      className={`fade-in-section ${isVisible ? 'is-visible' : ''}`}
      ref={domRef}
    >
      {props.children}
    </div>
  );
}

const aboutData = {
  2017: [
    { type: "Era", info: "The Flash Game Era" },
    { type: "Event", info: "October 2017 - The first games are published to Kongregate, including the Idle Streamer series" },
  ],
  2018: [
    { type: "Event", info: "June 2018 - Idle Hacking is released and featured on the Kongregate homepage with 150,000+ gameplays", img: "/games/images/idle-hacking.webp" },
    { type: "Event", info: "August 2018 - The sequel to Idle Hacking, rebranded as OutHack, is released, reaching 130,000+ gameplays", img: "/games/images/outhack.webp" },
  ],
  2019: [
    { type: "Event", info: "March 2019 - Bit Warfare is released, reaching 70,000+ gameplays", img: "/games/images/bit-warfare.webp" },
    { type: "Era", info: "The GTA 5 Mods Era" },
    { type: "Event", info: "August 2019 - The first GTA 5 mods are posted on 5Mods, first being Damage Effects", img: "/mods/images/damage-effects.webp" },
  ],
  2020: [
    { type: "Event", info: "April 2020 - The Kill Cam GTA 5 mod is released and featured on 5Mods", img: "/mods/images/kill-cam.webp" },
  ],
  2021: [
    { type: "Era", info: "The Video Era" },
    { type: "Event", info: "November 2021 - 'Lamar roasts Franklin but gets hit by a car' is uploaded to YouTube, gaining a whopping 40 views in the first week", img: "/images/roast-car.webp" },
  ],
  2022: [
    { type: "Event", info: "January 2022 - The previous upload gains interest on YouTube, marking the start of a popular series of GTA 5 machinima videos" },
    { type: "Event", info: "April 2022 - SilverFinish reaches 10,000 subscribers on YouTube" },
    { type: "Event", info: "May 2022 - SilverFinish reaches 50,000 followers on TikTok" },
    { type: "Event", info: "August 2022 - SilverFinish reaches 25,000 subscribers on YouTube" },
    { type: "Event", info: "October 2022 - Supplied intro machinimas for DarkViperAU's 'Can You Complete GTA 5 Without Wasting Anyone? - Final Pacifist%'", img: "/images/pacifist-intro.webp", url: "https://www.youtube.com/watch?v=lXo7i4zS-As" },
  ],
  2023: [
    { type: "Event", info: "March 2023 - Created the 'Cougars' GTA V challenge mod for DarkViperAU", img: "/images/mod-cougars.webp", url: "https://www.youtube.com/watch?v=7ZmGNwIDy3g" },
    { type: "Event", info: "October 2023 - SilverFinish reaches 50,000 subscribers on YouTube" },
  ],
};

function About(props) {
  PageInfo(props);

  return (
    <div className="text-align-center">

      <div className="title-section">
        About
      </div>

      <div className="about">
        <div className="general">
          I'm a software engineer and content creator, with over 45,000,000 views and 140,000 followers across all platforms.
          My experience spans across full stack development, game development, modding and video editing.
          <br/><br/>
          <div className="inline-block">Email:&nbsp;</div><div className="contact inline-block" data-box="sf" data-dom="silverfinish.net" title="Unselectable for spambot protection"/>
          <br/>
          <br/>
          <div>Discord: @silverfinish</div>
        </div>
      </div>

      <div className="title-section">
        Timeline
      </div>

      <div className="about">
        <div className="general">

          {Object.keys(aboutData).map(year => {
            let count = 0;
            return aboutData[year].map(yearData => {
              count++;

              const yearDiv = (count === 1) ?
                <div>
                  <FadeInSection>
                    <div className="font-size-48 bold padding-24">
                      {year}
                    </div>
                    <div className="timeline"/>
                  </FadeInSection>
                </div>
                : null;

              if (yearData.type === "Event") {
                return (
                  <div key={`${year}-${count}`}>
                    {yearDiv}
                    <div>
                      <FadeInSection>
                        <div className="padding-24">
                          <div>
                            {yearData.info}
                          </div>
                          {(yearData.img) ? 
                            (yearData.url) ? 
                              <a href={yearData.url}><div className="padding-12"><img className="img-resize shadow" src={yearData.img} alt={yearData.imgAlt} /></div></a>
                            :
                              <div className="padding-12"><img className="img-resize shadow" src={yearData.img} alt={yearData.imgAlt} /></div>
                          : null}
                        </div>
                        <div className="timeline"/>
                      </FadeInSection>
                    </div>
                  </div>
                );
              } else if (yearData.type === "Era") {
                return (
                <div key={`${year}-${count}`}>
                  {yearDiv}
                  <div>
                    <FadeInSection>
                      <div className="font-size-24 bold italic padding-24">
                        {yearData.info}
                      </div>
                      <div className="timeline"/>
                    </FadeInSection>
                  </div>
                </div>
                );
              }
              return null;
            })
          })}

          <FadeInSection>
            <div className="padding-24">
              The Future - ???
            </div>
          </FadeInSection>

        </div>
      </div>
      
    </div>
  )
}

export default About;