import { Link } from "react-router-dom";

function VidSim(props) {
  return (
    <div className="navbar-links">
      <div className="navbar-item">
        <Link className="navbar-link" to="/vidsim">Dashboard</Link>
      </div>

      <div className="navbar-item">
        <Link className="navbar-link" to="/vidsim/videos">Videos</Link>
      </div>

    </div>
  );
}

export default VidSim;